import { useState } from "react";
import PhoneIcon from "../icons/PhoneIcon";
import TelegramIcon from "../icons/TelegramIcon";
import WhatsappIcon from "../icons/WhatsappIcon";
import "../styles/Footer.scss";
import { useMask } from "@react-input/mask";
import { message } from "antd";
import { getFormSubmissionCount, incrementFormSubmissionCount } from "../api/localStorageHelper";
import { sendMessage } from "../api/telegram";

const messages = [
  {
    messageSuccess: "Ваша заявка успішно надіслана!",
  },
  {
    messageError: "Заповніть усі поля!",
  },
  {
    messageLimit: "Ви досягли максимальної кількості заявок.",
  },
];

const Footer = () => {
  const inputRef = useMask({ mask: "+38(___)-___-__-__", replacement: { _: /\d/ } });
  const [messageApi, contextHolder] = message.useMessage();

  const [formValues, setFormValues] = useState({
    name: "",
    phone: "",
    text: "",
  });
  const messageError = () => {
    messageApi.open({
      type: "error",
      content: messages[1].messageError,
      className: "Message",
    });
  };

  const messageLimit = () => {
    messageApi.open({
      type: "warning",
      content: messages[2].messageLimit,
      className: "Message",
    });
  };

  const handleChange = (e: any) => {
    const { name, value, files, type } = e.target;
    if (name === "file" && files.length > 0) {
      setFormValues({
        ...formValues,
        [name]: files[0],
      });
    } else if (type === "radio") {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const submissionCount = getFormSubmissionCount();

    if (submissionCount >= 3) {
      messageLimit();
      return;
    }

    try {
      if (formValues.name && formValues.phone && formValues.text) {
        const message = `Нове повідомлення: %0A- Ім'я: ${formValues.name} %0A- Номер телефону: ${formValues.phone} %0A- Коментар: ${formValues.text}`;
        console.log(message);
        await sendMessage(message);
        openModal();
        // messageSuccess();
        incrementFormSubmissionCount();
      } else {
        messageError();
      }
    } catch (e) {
      console.log("Error");
    } finally {
    }
  };

  return (
    <div className="Footer" id="contacts">
      {contextHolder}
      <div className="FooterBody">
        <div className="FooterLeft">
          <div className="Footer_logo">
            <img src={process.env.PUBLIC_URL + "evolutionLogo.png"} alt="" />
            <h1>Energy Evolution</h1>
          </div>
          <div className="FooterLink">
            <a href="tel:+380668505656" rel="noopener noreferrer">
              <PhoneIcon></PhoneIcon>
            </a>
            <a href="https://t.me/AG_run" rel="noopener noreferrer">
              <TelegramIcon></TelegramIcon>
            </a>
            <a href="https://wa.me/380668505656">
              <WhatsappIcon></WhatsappIcon>
            </a>
          </div>
          <a href="tel:+380668505656" rel="noopener noreferrer" className="FooterPhone">
            +38(066)-850-56-56
          </a>
          <p style={{ fontWeight: "800", textAlign: "start" }}>Офіс та Склад:</p>
          <p style={{ margin: "0px", textAlign: "start" }}>03148, Україна, Київ, проспект Академіка Корольова, 1А</p>
          <p style={{ fontWeight: "800", textAlign: "start" }}>Графік Роботи:</p>
          <p style={{ margin: "0px", textAlign: "start" }}>Пн.–Пт: з 9:00 до 17:00</p>
          <p style={{ margin: "0px", textAlign: "start" }}>Сб–Нд: вихідний</p>
        </div>
        <div className="FooterRight">
          <h3>Відправте нам повідомлення</h3>
          <div className="FooterRightInputs">
            <form>
              <input
                id="name"
                type="text"
                name="name"
                className="input"
                value={formValues.name}
                onChange={handleChange}
                placeholder="Ім'я"
              />
              <input
                id="phone"
                type="phone"
                name="phone"
                className="input"
                ref={inputRef}
                value={formValues.phone}
                onChange={handleChange}
                placeholder="Телефон"
              />
              <input
                id="text"
                type="text"
                name="text"
                className="input"
                value={formValues.text}
                onChange={handleChange}
                placeholder="Повідомлення"
              />
              <button type="submit" onClick={handleSubmit}>
                Відправити
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="FooterUnder">
        <div className="FooterLine"></div>
        <div className="FooterUnderData">
          <p>© 2024 Energy Evolution. Всі права захищені.</p>
          <a href="https://abc-digital.com/">Made by ABC Digital</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
function openModal() {
  throw new Error("Function not implemented.");
}
